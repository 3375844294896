.custom_loader {
  display: flex;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
  }
  img {
    animation: rotate 1s linear infinite;
  }
  span {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 15px;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
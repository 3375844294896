.kanban-project-tabs {
  display: flex;
  overflow: hidden;
  width: 100%;
  &-margin {
    margin-bottom: 20px;
  }
  &-container {
    display: flex;
    align-items: center;
  }
  &-withArrows {
    /*width: calc(100% - 60px);*/
  }
  &-arrows {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    font-size: 22px;
  }
  &__item {
    width: 20%;
    flex-shrink: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    text-align: center;
    padding: 0 2px;
    overflow: hidden;
    border-bottom: 3px solid #EEF0F4;
    padding-bottom: 12px;
    span {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70%;
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #A2A9B9;
    }
    &-active {
      border-bottom-color: #2361FF;
      span {
        color: #000000;
      }
    }
  }
}

.kanban-dropdown-mobile {
  position: relative;
  width: 100%;
  border-radius: 5px;
  &__current {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    cursor: pointer;
    > div {
      font-size: 19px;
      line-height: 23px;
      font-weight: 700;
      font-family: Inter, sans-serif;
      max-width: 80%;
      margin-right: 20px;
    }
    //&-icon {
    //  width: 32px;
    //  height: 32px;
    //  color: #FFFFFF;
    //  background: #2361FF;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  border-radius: 5px;
    //  transform: rotate(90deg);
    //  font-size: 14px !important;
    //}
    &-icon {
      width: 42px;
      height: 42px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transform: rotate(90deg);
      font-size: 14px !important;
      background: #FFFFFF;
    }
  }
  &__dropdown {
    position: absolute;
    width: calc(100% - 30px);
    top: 50px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
    border-radius: 10px;
    padding: 10px 20px;
    max-height: 300px;
    overflow: auto;
    z-index: 2;
    &-item {
      padding: 10px 0;
      cursor: pointer;
      &-current-item {
        span {
          color: #2361ff;
        }
      }
      span {
        font-weight: 700;
        font-size: 13px;
      }
      &:hover {
        background: #f3f5fa;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .kanban-project-tabs__item span {
    width: 160px;
  }
}
@media only screen and (max-width: 1400px) {
  .kanban-project-tabs__item span {
    width: 140px;
  }
}
@media only screen and (max-width: 500px) {
  .kanban-dropdown-mobile {
    padding: 0 5px;
    width: 100%;
    display: none;
  }
}
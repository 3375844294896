$color_light: #ffffff;

$color_blue: #2361FF;
$color_blue_1: #293DED;
$color_blue_2: #122248;
$color_blue_3: #0f1f4a;

$color_black: #000000;

$color_red: #FF5C5C;

$color_green: #0B8156;
$color_green_1: #77A193;

$color_grey: #393B48;
$color_grey_2: #D4D7DE;
$color_grey_3: #A3A9B9;
$color_grey_4: #EEF0F4;
$color_grey_5: #F3F5FA;

$color_orange: #FECA46;

$color_grey_light: #8A93AD;
$color_grey_dark: #B4BED9;

$radius: 30px;

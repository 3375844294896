.client-terms-user-privacy-policy {
  * {
    font-family: Inter, sans-serif;
  }
  .modal__container {
    max-width: 500px;
    padding-top: 10px;
  }
  .modal__container__header-close {
    display: none;
  }
  h4 {
    color: #2361FF;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
  }
  img {
    display: block;
    margin: 34px auto 0 auto;
  }
  h2 {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    margin-top: 20px;
  }
  p {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
    margin: 20px auto 0 auto;
    max-width: 400px;
  }
}
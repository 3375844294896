.legal-ticket {
  &__heading {
    font-family: Inter;
    font-weight: 700;
    font-size: 21px;
  }

  &__body {
    margin-top: 45px;
  }

  &__btn {
    margin-top: 30px;
  }
}

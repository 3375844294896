@import "./src/assets/scss/variables";
.header {
  height: 66px;
  display: flex;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 10px;
  &__left {
    display: flex;
    align-items: center;
    width: 260px;
    flex-shrink: 0;
  }
  &__nav {
    display: flex;
    margin-left: 150px;

    li {
      margin-right: 50px;

      a {
        font-size: 15px;
        color: $color_black;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__logo {
    margin: 17px 30px;
  }
  &__right {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    height: 66px;

    &-icons {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }

    &-user {
      display: none;
    }
    &-user img {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      margin-left: 10px;
    }

    &-link {
      margin-left: 20px;
      position: relative;
      height: 42px;
      width: 42px;
      background: $color_light;
      border-radius: 10px;
      border: 1px solid #EEEFF6;
      display: flex;
      align-items: center;
      justify-content: center;
      &-squire {
        width: 10px;
        height: 10px;
        background: #ffffff;
        position: absolute;
        transform: rotate(45deg);
        top: 47px;
        right: 15px;
        z-index: 11;
      }
    }

    &-counter {
      position: absolute;
      border-radius: 50%;
      background: #FF5C5C;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      padding: 0 6px;
      font-size: 11px;
      height: 19px;
      box-sizing: border-box;
      font-weight: normal !important;
      margin-left: 15px;
      font-family: Inter, sans-serif;
      top: -2px;
      right: -7px;
    }

    button {
      padding: 0 25px;
      text-transform: uppercase;
    }
  }
  &__white-button {
    background: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    margin-right: 10px;
  }
  &__search-open {
    width: inherit;
    input {
      outline: none;
      margin-left: 5px;
      padding: 5px;
      border: none;
    }
    img {
      margin-right: 10px;
    }
  }
  &__search-hide {
    cursor: pointer;
    input {
      display: none;
    }
  }
}

.callVlo {
  width: 175px;
  height: 42px;
  border-radius: 100px;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  &__circle {
    width: 42px;
    height: 42px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    height: 42px;
    text-align: left;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    flex-grow: 1;
    margin-left: -2px;
    color: #FFFFFF;
    line-height: 13px;
    display: flex;
    align-items: center;
  }
  &:hover {
    opacity: 0.6;
  }
}

.header-credit-balance {
  padding: 10px;
  border-radius: 10px;
  border: 1px #EEEFF6;
  background: #F3F5FA;
  margin-left: 15px;
  display: flex;
  align-items: center;
  span {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-right: 15px;
    padding-left: 5px;
    b {
      font-size: 13px;
      font-weight: 600;
    }
  }
  img {
    cursor: pointer;
  }
}

@media only screen and (max-width: 500px) {
  .client_layout {
    .header__nav {
      display: none;
    }
    .header__right-icons {
      margin-right: 15px;
    }
    .header__right-icons + button {
      display: none;
    }
    .header__logo {
      margin: 17px 15px;
    }
    .header__right-user {
      display: block;
    }
    .header__right-link {
      margin-left: 10px;
    }
    .mobile-header {
      margin-top: -40px;
      margin-bottom: 30px;
    }
  }
  .header {
    button {
      display: none;
    }
  }
  .header__left {
    width: 120px;
  }
  .header__right {
    padding-right: 3px;
  }
}
.create_business_goal {
  h2 {
    color: #2361FF;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 5px;
    text-transform: uppercase;
    flex-grow: 1;
    text-align: center;
  }
  h3 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin-top: 20px;
  }
}
.service-provider-kanban {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  &-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &-column {
    width: 25%;
    padding-right: 10px;
    h4 {
      font-family: Inter, sans-serif;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      color: #000;
    }
  }
  &-item {
    background: #fff;
    border-radius: 10px;
    min-height: 168px;
    width: 265px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}


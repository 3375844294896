.auth-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  &__container {

  }
  &__body {
    background: #FFFFFF;
    padding: 32px;
    border-radius: 8px;
  }
  &__hr-header {
    position: relative;
    margin-top: 24px;
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
      position: absolute;
      width: 100%;
      background: #fff;
      display: block;
      left: 0;
      right: 0;
      width: fit-content;
      margin: 0 auto;
      top: -15px;
      padding: 0 20px;
      color: #484848;
    }
    hr {
      border-top: 1px solid #d3dfea;
    }
  }
  &__description {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-top: 35px;
    line-height: 18px;
  }
  &__input {
    display: flex;
    border-radius: 5px;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 45px;
    margin-top: 15px;
    &-icon {
      background: #f3f4f7;
      border-right: 1px solid rgba(0, 0, 0, 0.125);
      display: flex;
      width: 45px;
      align-items: center;
      justify-content: center;
      .svg-icon {
        color: #2361ff;
        // fill: #2361ff;
        fill: #000000;
      }
    }
    &-error {
      color: red;
      font-size: 13px;
      padding-top: 5px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: right;
      width: 100%;
    }
    input {
      border: none;
      flex-grow: 1;
      padding: 0 10px;
      &::placeholder {
        color: #b2b2b2;
        font-weight: 300;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__button {
    border: none;
    // background: #2361ff;
    background: #000;
    color: #fff;
    font-weight: 300;
    width: 100%;
    border-radius: 30px;
    padding: 14px;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 7px;
    }
  }
  &__close-button {
    color: #d7d7d7;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    &-link {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.auth-cols {
  .auth-modal__body {
    padding: 0;
    min-height: 520px;
  }
  .auth-modal__left-col {
    width: 52%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
  .auth-modal__right-col {
    width: 48%;
    background: #3761f0;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 460px) {
  .client_layout {
    .auth-modal__body {
      min-width: inherit !important;
      padding: 25px 10px;
    }
    .auth-modal__hr-header h1 {
      font-size: 20px;
    }
    .auth-modal__description {
      margin-top: 15px;
    }
    .auth-modal__container {
      width: 90%;
    }
  }
}
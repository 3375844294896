.payment-plan {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  &__tabs {
    display: flex;
  }
  &__tab {
    color: #7B8DB7;
    font-family: Inter, sans-serif;
    font-size: 13px;
    letter-spacing: -0.5px;
    font-weight: 400;
    padding: 8px 20px;
    border-bottom: 6px solid #FFFFFF;
    margin-right: 4px;
    cursor: pointer;
    &-active {
      border-color: #2f7dd9;
      color: #000;
      font-weight: 500;
      cursor: default;
    }
  }
  h2 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 700;
  }
  &__naming {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 45px;
    &-name {
      font-family: Inter, sans-serif;
      font-size: 21px;
      font-weight: 500;
    }
    &-contact-us {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.5px;
      a {
        color: #2361FF;
        cursor: pointer;
      }
    }
  }
}

.payment-plan__modal {
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  &__text {
    h3 {
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.5px;
      margin-top: 25px;
    }
    h4 {
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 26px;
      font-weight: 600;
      line-height: 28px;
      margin-top: 17px;
    }
    em {
      margin-top: 40px;
      display: block;
      text-align: center;
      color: #2361FF;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    p {
      margin-top: 8px;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.5px;
    }
  }
  &-success {
    border-radius: 10px;
    border: 1px solid #D2DAEC;
    background: #F3F5FA;
    padding: 21px;
    max-width: 246px;
    margin: 30px auto;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      font-family: Inter, sans-serif;
      font-size: 15px;
      letter-spacing: -0.5px;
      b {
        font-weight: 600;
      }
    }
  }
  &-info {
    text-align: center;
    margin-top: 26px;
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    em {
      color: #A3A9B9;
    }
  }
}
.client-kanban-item {
  background: #FFFFFF;
  border-radius: 10px;
  min-height: 168px;
  width: 265px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  * {
    text-decoration: none !important;
  }
  &:hover {
    box-shadow: 0px 4px 24px rgba(59, 74, 112, 0.16);
    cursor: pointer;
  }
  .header__right-link-squire {
    top: 31px;
    right: 8px;
  }
  .dropdown__container {
    right: -20px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #EEF0F4;
  }
  &__status {
    padding: 6px 10px;
    border-radius: 100px;
    background: #2361FF;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  &__progress {
    display: flex;
  }
  &__squire {
    width: 13px;
    height: 13px;
    background: #F3F5FA;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    &-inside {
      width: 9px;
      height: 9px;
      border-radius: 3px;
      background: rgba(138, 147, 173, 0.12);
    }
    &-green {
      background: #07B56C;
    }
  }
  &__dropdown-link {
    font-size: 13px;
    display: block;
    width: 120px;
    padding: 14px 15px;
    font-family: 'Inter', sans-serif;
    &:hover {
      color: #2361FF;
    }
  }
  &__body {
    flex-grow: 1;
    padding: 10px 20px;
    &-title {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #000000;
    }
  }
  &__steps {
    margin-top: 10px;
    display: flex;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.5px;
    &-name {
      color: #8A93AD;
      margin-right: 3px;
    }
    &-step {
      color: #000000;
    }
  }
  &__footer {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__manager-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #EEEFF6;
    padding: 10px;
    &-empty {
      display: block;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      letter-spacing: -0.5px;
      color: #919AB3;
      padding: 5px 0;
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    width: 100%;
    &-img {
      width: 27px;
      height: 27px;
    }
    &-message {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      letter-spacing: -0.5px;
      color: #000000;
      margin-left: 7px;
      margin-top: 2px;
    }
    &-name {
      width: 100%;
      span {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 11px;
        letter-spacing: -0.5px;
        color: #919AB3;
        margin-left: 7px;
      }
      &-top {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &__comment-date {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: -0.5px;
    color: #919AB3;
  }
  &__icons {
    display: flex;
    img {
      margin-right: 13px;
    }
    .dropdown__notifications__relative {
      //display: none;
    }
  }
  &__users {
    display: flex;
  }
  &__user {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-left: 3px;
    img {
      margin-left: 8px;
      border-radius: 6px;
    }
  }
  &__company {
    padding: 1px 0;
    min-height: 28px;
  }
  &__company-name {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: -0.5px;
    color: #919AB3;
  }
  &__comment-items {
    background: #FF4646;
    border-radius: 4px;
    color: #FFFFFF;
    height: 14px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 1500px) {
  .client-kanban-item {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .client-kanban-item {
    width: calc(100% - 20px);
    margin: 10px 10px 0 10px;
  }
}
.checkout {
  display: flex;
  justify-content: space-between;
  * {
    font-family: "Inter", sans-serif;
  }
  &__left {
    flex-grow: 1;
  }
  &__right {
    width: 400px;
    margin-left: 30px;
    height: max-content;
    flex-shrink: 0;
  }
  &__container {
    background: #FFFFFF;
    border-radius: 10px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    margin-bottom: 11px;
    border-bottom: 1px solid #F2F4FA;
    &-name {
      display: flex;
      h2 {
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
      }
      h3 {
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        margin-left: 22px;
      }
    }
    &-download {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #000000;
      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.5px;
        margin-right: 10px;
      }
    }
  }
  &__description {
    padding: 10px 30px 30px 30px;
    &-row {
      display: flex;
      margin-bottom: 15px;
    }
    &-name {
      width: 120px;
      flex-shrink: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
    &-value {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.5px;
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #000000;
    padding: 24px 0;
    margin: 0 30px;
    &-name {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
    &-value {
      font-weight: 600;
      font-size: 21px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }
  }
  &__heading {
    padding: 22px 30px 18px 30px;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    border-bottom: 1px solid #F2F4FA;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__bankTransfer {
    padding-bottom: 60px;
  }
  &__payment-description {
    ul {
      margin-left: 53px;
      margin-top: 20px;
    }
    li {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.5px;
      text-transform: initial;
      margin-bottom: 20px;
      list-style: disc;
      &:last-child {
        margin-bottom: 0;
      }
      b {
        font-weight: 600;
      }
    }
    &-noList {
      list-style: none !important;
    }
  }
  &__payment-types {
    padding: 23px 30px;
    &-label {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #A3A9B9;
    }
  }
  &__payment-type {
    padding: 26px 20px;
    border: 1px solid #F3F5FA;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &-container {
      display: flex;
      align-items: center;
      position: relative;
    }
    &-image {
      margin-left: 19px;
    }
    &-name {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-left: 13px;
      margin-top: 2px;
    }
    &:hover {
      box-shadow: 0px 4px 24px rgba(59, 74, 112, 0.1);
    }
  }
  &__radio {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    border: 1px solid #DBE1EF;
    flex-shrink: 0;
    &-active {
      width: 11px;
      height: 11px;
      background: #2361FF;
      box-shadow: 0px 4px 4px rgba(35, 97, 255, 0.5);
      border-radius: 50%;
    }
  }
  &__radioSelect {
    display: flex;
  }
  &__radioContainer {
    display: flex;
    align-items: center;
    margin-right: 42px;
    cursor: pointer;
    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.5px;
      margin-left: 9px;
    }
  }
  &__form {
    width: 390px;
    margin: 0 auto;
    &-heading {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
    &-mt5 {
      margin-top: 5px;
    }
    &-mt15 {
      margin-top: 15px;
    }
    &-mt30 {
      margin-top: 30px;
    }
    &-w50 {
      width: 49%;
    }
    &-pb60 {
      padding-bottom: 60px;
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    span {
      font-weight: 400;
      font-size: 13px;
      display: block;
    }
    b {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
  }
  &__button {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: table;
    margin: 0 auto;
    padding: 0 38px;
  }
  &__invoice {
    &-description {
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: -0.5px;
      padding: 15px 30px 30px 30px;
    }
    &-edit {
      height: 32px;
      width: 92px;
      background: #F3F5FA;
      border: 1px solid #CDD1DD;
      border-radius: 10px;
      padding: 0 9px 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-weight: 500;
      line-height: 13px;
      text-transform: uppercase;
    }
    &-table {
      padding: 30px 0;
      margin: 0 auto;
      width: 400px;
      h2 {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 15px;
      }
      em {
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.5px;
        text-decoration-line: underline;
        margin-top: 19px;
        display: block;
      }
    }
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3px;
      b {
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
      }
      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        display: block;
        text-align: right;
        padding-left: 10px;
        width: 75%;
      }
    }
    &-hr {
      height: 1px;
      width: 100%;
      background: #000000;
    }
  }
  &__checkbox-container {
    &-text {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      text-transform: uppercase;
      color: #A3A9B9;
      max-width: 318px;
      margin: 26px auto 0 auto;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 25px auto 0 auto;
    cursor: pointer;
    span {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0 15px;
    }
    &__container {
      width: 62px;
      height: 33px;
      background: #F3F5FA;
      border: 1px solid #E0E3ED;
      border-radius: 100px;
      display: flex;
      transition: all .5s ease;
      &-active {
        justify-content: flex-end;
      }
    }
    &-active {
      width: 28px;
      height: 28px;
      background: #2361FF;
      box-shadow: 0 4px 4px rgba(35, 97, 255, 0.5);
      border-radius: 100px;
      margin: 2px;
    }
  }
  &__country {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    span {
      color: #8f8f8f;
    }
    b {
      font-weight: 600;
    }
    button {
      background: #2361FF;
      color: #FFFFFF;
      font-size: 12px;
      border-radius: 4px;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
    }
  }
  &__fastest_payment {
    background: #000000;
    color: #FFFFFF;
    border-radius: 100px;
    font-size: 11px;
    font-weight: 500;
    padding: 3px 10px;
    position: absolute;
    right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .checkout {
    &__country {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      span, b, button {
        display: block;
        margin-top: 3px;
      }
    }
    &__right {
      display: none;
    }
    &__left {
      margin: 0 10px;
    }
    &__description-name {
      width: 100px;
      font-size: 13px;
    }
    &__description-row {
      flex-direction: column;
    }
    &__invoice-row {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
      span {
        width: 100%;
        text-align: left;
        padding-left: 0;
      }
    }
    &__invoice-table {
      padding: 20px 15px;
    }
    &__fastest_payment {
      position: inherit;
    }
  }
}
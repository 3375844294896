.monthly_report_page {
  &__heading {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
  }
  &__desc {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 42px;
    display: block;
  }
  &__container {
    background: #FFFFFF;
    border-radius: 10px;
    width: 100%;
    padding: 60px 55px;
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    p {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      a {
        display: block;
        color: #2361FF;
      }
    }
  }
  &__checkbox {
    margin-top: 50px;
  }
  &__left-col {
    width: 365px;
  }
  &__right-col {
    width: 390px;
  }
  .react-daterange-picker__range-divider {
    display: none !important;
  }
  .react-daterange-picker__inputGroup {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 42px;
    position: relative;
    box-sizing: border-box;
    padding: 2px 70px 2px 10px;
    cursor: pointer;
    &:first-child {
      margin-right: 10px;
    }
    * {
      font-family: 'Inter', sans-serif !important;
      font-weight: 400;
      font-size: 13px;
    }
    input {

    }
  }
  .react-daterange-picker__wrapper {
    border: none;
  }
  &__date {
    position: relative;
    &-container {
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 42px;
      position: relative;
      box-sizing: border-box;
      margin-top: 23px;
      padding: 2px 8px;
      cursor: pointer;
      b {
        font-family: 'Inter', sans-serif;
        position: absolute;
        background: #fff;
        padding: 0 8px;
        top: -4px;
        left: 12px;
        font-size: 11px;
        z-index: 1;
        color: #8A93AD;
      }
      span {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
    }
    &-dropdown {
      position: absolute;
      background: #FFFFFF;
      border: 1px solid #B4BED9;
      border-radius: 5px;
      width: 100%;
      margin-top: 4px;
      &-heading {
        padding: 12px 0;
        width: 100%;
        text-align: center;
        font-family: 'Inter', sans-serif;
        border-bottom: 1px solid #B4BED9;
        letter-spacing: -0.5px;
        text-transform: uppercase;
        color: #A3A9B9;
        font-weight: 500;
        font-size: 11px;
      }
    }
  }
  &__button {
    margin-top: 39px;
    width: 100%;
  }
  &__range {
    width: 330px;
    margin: 0 auto;
    padding: 15px 0;
    position: relative;
    &-label {
      font-family: 'Inter', sans-serif;
      letter-spacing: -0.5px;
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 8px;
    }
    &-calendar1 {
      position: absolute;
      z-index: 1;
      top: 45px;
      left: 130px;
    }
    &-calendar2 {
      position: absolute;
      z-index: 1;
      top: 45px;
      left: 300px;
    }
  }
  &__period-range {
    width: 330px;
    margin: 15px auto 0 auto;
    a {
      display: block;
      font-weight: 400;
      font-size: 13px;
      line-height: 26px;
      letter-spacing: -0.5px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .monthly_report_page {
    margin: 0 15px;
    &__left-col {
      width: 100%;
    }
    &__right-col {
      width: 100%;
    }
    &__container {
      flex-direction: column;
      padding: 25px 20px;
    }
    &__desc {
      margin-top: 10px;
    }
    &__button {
      margin-top: 20px;
    }
  }
}
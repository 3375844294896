@import "./src/assets/scss/variables";

.task-status {
  position: relative;
  &__current {
    border: 1px solid #B4BED9;
    border-radius: 100px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 133px;
    padding: 0 15px;
    font-family: Inter;
    font-size: 14px;
    cursor: pointer;
    img {
      margin: 0;
    }
  }
  &__body {
    padding: 20px 15px;
    background: $color_light;
    box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
    border-radius: 10px;
    margin-top: 3px;
    position: absolute;
    top: 36px;
    width: 100%;
    left: 0;
    &-item {
      display: flex;
      align-items: center;
      font-family: Inter;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &-circle {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #C4C4C4;
        margin-right: 5px;
      }
      &:hover {
        color: $color_blue !important;
      }
    }
  }
}

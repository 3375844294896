.planner-page {
  * {
    font-family: Inter, sans-serif;
  }
  &__list {
    max-width: 620px;
    margin: 40px auto 0 auto;
  }

  &__list-item {
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 12px;
    &-heading {
      padding: 20px;
      border-bottom: 1px solid #EEEFF6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-right {
        display: flex;
        margin-left: 10px;
        img {
          margin-right: 8px;
          &:hover {
            opacity: 0.7;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
        button {
          min-width: 110px;
          padding: 6px 10px;
          background: #000000;
          color: #FFF;
          font-size: 11px;
          font-weight: 500;
          text-transform: uppercase;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
            box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
          }
        }
      }
    }
    &-body {
      color: #000;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      padding: 20px;
    }
    &-date {
      em {
        color: #919AB3;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }
    }
    &-title {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.5px;
      margin-top: 5px;
    }
  }

  &__create-button {
    width: 42px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 -5px -5px 0;
    }
  }

  &__modal-container {
    border-radius: 10px;
    border: 1px solid #EEEFF6;
    background: #F3F5FA;
    padding: 14px 25px 14px 15px;
    width: max-content;
    font-size: 13px;
    margin: 0 auto;
  }
}
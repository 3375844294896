.delete-follow-up-modal-confirm {
  .modal__container__header-close {
    display: none;
  }
  .modal__container {
    max-width: 510px;
  }
  .modal__body {
    overflow: hidden;
  }
  h2 {
    color: #FF5C5C;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
  }
  img {
    display: block;
    margin: 27px auto 0 auto;
  }
  p {
    color: #000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 23px;
  }
  button {
    font-family: Inter, sans-serif;
  }
}
.follow-up-modal {
  .modal__body, .modal__container {
    overflow: visible !important;
  }
  h3 {
    color: #2361FF;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
  }
  h4 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: normal;
    margin-top: 40px;
  }
}
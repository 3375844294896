.notification {
  position: absolute;
  width: 329px;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(18, 34, 72, 0.15);
  border-radius: 10px;
  top: 50px;
  right: -20px;
  padding: 10px 0 0 0;
  cursor: default;
  z-index: 10;
  &__empty {
    font-size: 13px;
    text-align: center;
    padding-top: 10px;
  }
  &__container {
    padding: 20px 20px;
    max-height: 300px;
    overflow: auto;
  }
  &__btn {
    width: 80%;
    margin: 10px auto 10px auto;
    display: block;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    padding-bottom: 15px;
    &:last-child {
      padding-bottom: 0;
    }
    &-text{
      flex-grow: 1;
      p {
        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.73px;
        cursor: pointer;
      }
    }
    &-icon {
      padding-right: 14px;
    }
    &-date {
      font-family: Inter;
      font-weight: 400;
      color: #A2A9B9;
      display: block;
      padding-top: 5px;
      font-size: 11px;
    }
    &-close {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 460px) {
  .client_layout {
    .notification {
      right: -60px;
      width: 300px;
    }
  }
}
@import "./variables";

a {
  cursor: pointer;
  &:hover {
    text-decoration: underline
  }
}
.btn {
  &-black {
    background: $color_black;
    color: $color_light;
    border-radius: 100px;
    height: 42px;
    font-size: 11px;
    border: none;
    cursor: pointer;
    &:disabled {
      opacity: .5;
      cursor: default;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  &-white {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 100px;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #f3f3f3;
    }
    &:disabled {
      opacity: .5;
      cursor: default;
    }
  }


  &_full-width {
    width: 100%;
  }
  &:disabled {
    opacity: .5;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

* button:disabled {
  cursor: not-allowed !important;
}
.follow-up-view {
  &__back {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #2361FF;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.5px;
    img {
      margin-right: 7px;
    }
  }
  &__content {
    flex-grow: 1;
    padding-right: 20px;
  }
  &__heading {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 26px;
      font-weight: 700;
    }
  }
  &__body {
    margin-top: 16px;
    border-radius: 10px;
    background: #FFF;
    &-heading {
      color: #FFF;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 18px 26px;
      border-radius: 10px 10px 0 0;
    }
    &-html {
      padding: 25px 30px;
    }
    .react-editor {
      width: 100%;
      padding-bottom: 20px;
    }
    .ql-toolbar.ql-snow {
      border-radius: 10px 10px 0 0;
    }
    .ql-container.ql-snow {
      border-radius: 0 0 10px 10px;
    }
  }
  &__actions {
    display: flex;
    &-delete {
      width: 42px;
      height: 42px;
      flex-shrink: 0;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
    &-edit {
      padding: 0 40px;
      height: 42px;
      border-radius: 100px;
      border: 1px solid #000;
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      background: none;
      &:hover {
        opacity: 0.6;
      }
    }
    &-publish {
      padding: 0 40px;
    }
  }
  &__sidebar {
    width: 490px;
    flex-shrink: 0;
  }
  &__call-info {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 85px;
    &-heading {
      border-radius: 10px 10px 0 0;
      padding: 17px 20px;
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: 1px solid #EEEFF6;
    }
    &-body {
      padding: 17px 20px;
      display: flex;
      flex-wrap: wrap;
      &-info {
        width: 50%;
        span {
          color: #A3A9B9;
          font-family: Inter, sans-serif;
          font-size: 11px;
          font-weight: 400;
        }
        p {
          font-family: Inter, sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
        }
        &:nth-child(3n), &:nth-child(4n) {
          margin-top: 25px;
        }
      }
    }
  }
  &__files {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 10px;
    &-heading {
      padding: 8px 20px;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EEEFF6;
      span {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &-upload {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
      b {
        color: #2361FF;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-top: -3px;
        display: block;
      }
      p {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: -0.5px;
        margin-top: 3px;
      }
      input {
        overflow: hidden;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        border: none;
      }
      &:hover {
        opacity: 0.6;
      }
    }
    &-list {
      padding: 5px 20px 20px 20px;
    }
    &-item {
      display: flex;
      margin-top: 15px;
      align-items: center;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
      }
      &-right {
        display: flex;
        align-items: center;
        button {
          height: 26px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #000;
          align-items: center;
          display: flex;
          color: #FFF;
          font-family: Inter, sans-serif;
          font-size: 11px;
          font-weight: 500;
          text-transform: uppercase;
          padding: 0 10px;
          cursor: pointer;
          border: none;
          margin-right: 5px;
          &:hover {
            opacity: 0.6;
          }
        }
        img {
          cursor: pointer;
        }
      }
      &-icon {
        width: 26px;
        height: 26px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #4E7C4D;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
      }
    }
    &-loader {
      font-family: Inter, sans-serif;
      padding: 15px 20px;
      font-size: 13px;
    }
  }
}
.header_timer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EEEFF6;
  border-radius: 10px;
  margin-left: 20px;
  padding: 0 20px;
  height: 42px;
  &__info {
    display: flex;
    align-items: center;
  }
  &__company {
    font-size: 13px;
    font-weight: bold;
  }
  &__type {
    font-size: 13px;
  }
  &__block {
    font-size: 13px;
    margin-left: 10px;
    color: #FF5C5C;
  }
  &__button {
    background: none;
    width: 22px;
    height: 22px;
    border: 1px solid #888888;
    border-radius: 50%;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
  }
  &__triangle {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-left: 8px solid #888888;
    border-bottom: 6px solid transparent;
    margin-left: 2px;
  }
  &__square {
    width: 10px;
    height: 10px;
    background: #FF5C5C;
  }
}

.all_timely_events {
  position: relative;

  &__container {
    padding-top: 0;
    cursor: pointer;
  }
  &__heading {
    margin: 0 -20px;
    display: flex;
    align-items: center;
    padding: 5px 20px 10px 20px;
    border-bottom: 1px solid #bbbbbb;
    img {
      margin-right: 10px;
    }
    span {
      color: #2361FF;
      font-size: 12px;
      font-weight: 500;
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
    img {
      position: relative;
      top: 2px;
    }
    div {
      margin-left: 12px;
    }
    span {
      font-size: 13px;
      color: #000000;
      b {
        font-weight: bold;
      }
    }
    em {
      color: #b9beca;
      font-size: 12px;
      font-weight: 300;
    }
  }
}
@import "./src/assets/scss/variables";

.options {
  display: flex;
  &__item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    &-inactive {
      background: #F3F5FA;
      height: 18px;
      width: 18px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0px 0px 4px rgba(155, 172, 233, 0.25);
    }
    &-active {
      width: 8px;
      height: 8px;
      background: $color_blue;
      border-radius: 50%;
    }
    &:hover {
      cursor: pointer;
    }
    &-label {
      margin-left: 5px;
    }
  }
}

.sort_kanban_board {
  position: relative;
  &__current {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    background: #FFFFFF;
    width: 300px;
    border: 1px solid #EEEFF6;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.5px;
    cursor: pointer;
    em {
      color: #919AB3;
    }
  }
  &__dropdown {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
    border-radius: 10px;
    margin-top: 5px;
    padding: 8px 0;
    z-index: 2;
    width: 350px;
    &-el {
      padding: 9px 16px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: -0.5px;
      cursor: pointer;
      &-active,
      &:hover {
        background: #F3F5FA;
        color: #2361FF;
      }
    }
  }
}
.no-calls-screen {
  border-radius: 10px;
  border: 1px solid #DADEEA;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-all {
    padding: 90px 0;
    margin-top: 50px;
  }
  &-upcoming {
    padding: 28px 0;
    margin-top: 14px;
  }
  p {
    color: #919AB3;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
  }
  button {
    width: 149px;
    height: 42px;
    border-radius: 1000px;
    background: #0B4CFF;
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
.mobile-legal-board-heading {
  display: none;
  align-items: center;
  margin: 0 10px 20px 10px;
  padding: 20px 0;
  border-top: 1px solid #EEF0F4;
  border-bottom: 1px solid #EEF0F4;
  img {
    margin-right: 12px;
  }
}

.mobile-nav-modal-window {
  background: #FFFFFF;
  margin-top: 63px;
  padding: 15px 20px;
  &__container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(000,000,000,0.7);
    z-index: 3;
  }
  &__heading {
    display: flex;
    align-items: center;
    font-weight: bold;
    img {
      margin-right: 12px;
    }
  }
  &__nav {
    margin: 50px 0 0 18px;
  }
  &__link {
    text-decoration: none;
    margin-bottom: 32px;
    display: block;
    > div {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 8px;
    }
    span {
      font-size: 15px;
      font-weight: 600;
      color: #000000;
    }
  }
  &__sub-link {
    text-decoration: none;
    margin-bottom: 15px;
    display: block;
    margin-left: 25px;
    span {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 15px;
      color: #000000;
    }
  }
  &__footer {
    padding: 15px 20px 0 20px;
    border-top: 1px solid #EEF0F4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -20px;
    button {
      width: calc(50% - 5px);
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 11px;
      border-radius: 100px;
      background: #FFFFFF;
      text-transform: uppercase;
    }
    &-btn-black {
      background: #000000 !important;
      color: #FFFFFF;
      border: none;
    }
  }
}

@media only screen and (max-width: 500px) {
  .mobile-legal-board-heading {
    display: flex;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.container {
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EAE9F2;
  box-sizing: border-box;
  border-radius: 5px;
  flex-shrink: 0;
  background: #FFFFFF;
}
.containerActive {
  background: #000000;
}
.squire {
  width: 12px;
  height: 12px;
  background: #000000;
  border-radius: 2px;
}
.label {
  font-family: Inter, sans-serif;
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 15px;
  word-break: break-word;
  a {
    color: #2361FF !important;
    font-weight: 700;
  }
}
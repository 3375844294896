.offer-deliverable-modal {
  .react-select__menu {

  }
  .checkbox__item {
    align-items: center;
  }
  .modal__container {
    width: 510px;
  }
  .modal__container__header {
    h2 {
      font-family: Inter, sans-serif;
      color: #2361FF;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 5px;
    }
  }
  .modal__body {
    padding-left: 0;
  }
  &__heading {
    font-size: 21px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 20px;
    b {
      color: #808080;
    }
  }
  &__not-required {
    margin-top: 15px;
  }
  &__hr {
    padding: 5px 0;
    hr {
      border-top: 0;
      border-color: #E5EAF8;
    }
    p {
      color: #B4BED9;
      font-size: 11px;
      margin: -13px auto;
      text-align: center;
      background: #fff;
      width: 30px;
    }
  }
}
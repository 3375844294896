.nps {
  &-close {
    position: absolute;
    right: 12px;
    top: 38%;
    font-size: 28px;
    color: #ff5c5c;
    cursor: pointer;
  }
  &-show {
    bottom: 0 !important;
  }
  &-dropdown {
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    position: fixed;
    bottom: -520px;
    left: 0;
    right: 0;
    padding: 10px;
    z-index: 2;
    -webkit-transition: all 0.4s ease-out 0.1s;
    -moz-transition: all 0.4s ease-out 0.1s;
    -o-transition: all 0.4s ease-out 0.1s;
    transition: all 0.4s ease-out 0.1s;
    h4 {
      font-family: 'Inter', sans-serif;
      text-align: center;
      font-weight: 400;
      padding-bottom: 5px;
      font-size: 13px;
    }
    &__container {
      /*max-width: 1600px;*/
      max-width: max-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    &__sub {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-family: 'Inter', sans-serif;
        font-size: 11px;
        color: #343434;
        font-weight: 300;
        margin-top: 2px;
      }
    }
    &__numbers {
      display: flex;
      margin-top: 6px;
    }
    &__number {
      background: #2361ff !important;
      color: #FFFFFF !important;
      height: 37px;
      width: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
      border-radius: 4px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    /*&__number {
      padding: 9px 25px;
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      &:first-child {
        border-radius: 20px 0 0 20px;
        padding-left: 28px;
      }
      &:nth-child(6) {
        border-radius: 0 20px 20px 0;
        padding-right: 30px;
      }
      &:nth-child(7) {
        margin-left: -13px;
        padding-left: 30px;
      }
      &:nth-child(8) {
        border-radius: 0 20px 20px 0;
        padding-right: 30px;
      }
      &:nth-child(9) {
        margin-left: -13px;
        padding-left: 35px;
      }
      &:last-child {
        border-radius: 0 20px 20px 0;
        padding-right: 28px;
      }
      &:hover {
        background: #838383 !important;
        cursor: pointer;
      }
    }*/
  }

  &__questions {
    max-width: 800px;
    margin: 0 auto;
  }
  &__question {
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    &-heading {
      font-family: 'Inter', sans-serif;
      padding: 15px;
      background: #f3f3f3;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-icons a {
        font-size: 22px;
        text-decoration: none;
        cursor: pointer;
        margin-left: 10px;
      }
    }
    &-body {
      padding: 10px;
      background: #FFFFFF;
      &-quiz {
        display: flex;
        a {
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
          font-family: 'Inter', sans-serif;
          text-decoration: none;
          background: #f3f3f3;
          color: #2b2b2b;
          font-size: 14px;
          &:hover {
            background: #2361FF;
            color: #fff;
          }
        }
        &-active {
          background: #2361FF !important;
          color: #fff !important;
        }
      }
      textarea, input {
        background: #fdfdfd;
        border: 1px solid #e3e3e3;
        width: 100%;
        padding: 10px;
        font-family: 'Inter', sans-serif;
        &:focus {
          outline: none;
        }
      }
    }
    &-modal {
      &-numbers {
        display: flex;
        margin-top: 10px;
        &-active {
          background: #000000 !important;
          color: #fff !important;
        }
        a {
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
          font-family: 'Inter', sans-serif;
          text-decoration: none;
          background: #f3f3f3;
          color: #2b2b2b;
          font-size: 14px;
        }
      }
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    em {
      font-family: 'Inter', sans-serif;
      margin-left: 10px;
      font-size: 14px;
      color: #ff5c5c;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__button {
    font-family: 'Inter', sans-serif;
    background: #000000;
    border: none;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 25px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  &__question-modal {
    .modal__container {
      max-width: 700px;
      width: 700px;
    }
  }
  &__finish-text {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    h2 {
      font-family: 'Inter', sans-serif;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
    p {
      font-family: 'Inter', sans-serif;
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      margin: 10px 0;
      line-height: 20px;
    }
    button {
      margin: 0 auto;
      display: block;
    }
  }
}

.nps-results {
  &__item {
    margin-bottom: 10px;
    background: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    &-heading {
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 8px;
      div {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
      }
    }
    &-body {
      padding-top: 8px;
      li {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        list-style-type: circle;
        margin-left: 20px;
        padding: 3px 0;
        line-height: 18px;
      }
    }
  }
}
@import "./src/assets/scss/variables";

.checkbox {
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-top: 10px;
    &-element {
      background: #F3F5FA;
      height: 18px;
      width: 18px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0px 0px 4px rgba(155, 172, 233, 0.25);
      margin-right: 4px;
      flex-shrink: 0;
    }
    &-active {
      background: $color_blue;
    }
    &-disabled {
      background: none;
      border: none !important;
      box-shadow: none !important;
    }
    &-label {
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      word-break: break-word;
    }
  }
}

.crypto-payment-page {
  display: flex;
  justify-content: center;
  h2 {
    font-family: Inter, sans-serif;
    font-size: 21px;
    line-height: 26px;
    font-weight: 600;
  }
  &__container {
    background: #FFFFFF;
    border-radius: 10px;
    width: 100%;
    display: flex;
  }
  &__left {
    width: 50%;
    padding: 30px;
    h4 {
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.5px;
      margin-top: 20px;
    }
    p {
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      margin-top: 20px;
      b {
        font-weight: 700;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  &__right {
    width: 50%;
  }
  &__heading {
    border-bottom: 1px solid #F2F4FA;
    padding: 20px 30px;
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-weight: 600;
  }
  &__body {
    padding: 30px;
    h4 {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.5px;
      b {
        color: #2361FF;
      }
    }
    p {
      margin-top: 10px;
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      b {
        font-weight: 600;
      }
    }
  }
  &__block {
    border-radius: 10px;
    border: 1px #D2DAEC;
    background: #F3F5FA;
    padding: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-right: 10px;
    }
    span {
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.5px;
      em {
        font-family: Inter, sans-serif;
        font-size: 9px;
      }
    }
    b {
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.5px;
      text-wrap: nowrap;
    }
  }
  &__qr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    &-text {
      font-family: Inter, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.5px;
      display: flex;
      align-items: center;
      img {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
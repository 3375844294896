.follow-up-list {
  padding: 15px;
  align-items: flex-start;
  max-width: 900px;
  margin: 0 auto;
  h1 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 700;
  }
  h2 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 35px;
  }
  display: flex;
  &__content {
    flex-grow: 1;
    padding-right: 20px;
  }
  &__new-call {
    margin-top: 30px;
    padding: 25px 20px;
    background: #FFFFFF;
    flex-shrink: 0;
    width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    button {
      margin-top: 25px;
    }
    h5 {
      margin-top: 20px;
      color: #000;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      color: #000;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.5px;
      margin-top: 10px;
    }
  }
  &__table {
    margin-top: 18px;
    width: 100%;
    &-tr {
      display: flex;
    }
    &-th {
      color: #A3A9B9;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 400;
      padding-left: 20px;
    }
  }
  &__filter-select {
    /*width: 300px;*/
    min-width: 200px;
  }
  &-filter {
    margin-top: 10px;
    justify-content: flex-end;
    .react-select__control {
      border: 1px solid #EEEFF6;
    }
  }
  &__not-found {
    max-width: 450px;
    margin: 130px auto;
    &-heading {
      color: #8A93AD;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 28px;
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      margin-top: 30px;
      color: #8A93AD;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.5px;
    }
  }
}


.follow-up-list-item {
  margin-top: 5px;
  padding: 24px 20px;
  border-radius: 10px;
  background: #FFF;
  display: flex;
  align-items: center;
  &-td {
    padding-left: 30px;
    position: relative;
    b {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 600;
      line-height: 17px;
    }
    p {
      color: #000;
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
    }
    em {
      color: #2361FF;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.5px;
    }
    &:first-child {
      padding-left: 0;
    }
    &:hover div.follow-up-list-item-tooltip {
      display: flex;
    }
  }
  &-button {
    border-radius: 100px;
    width: 145px;
    border: 1px solid #000;
    padding: 14px 0;
    color: #ffffff;
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #000000;
    cursor: pointer;
    &:hover {
      background: #4f4f4f;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
  &-toggle {
    position: relative;
    img {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    &-squire {
      position: absolute;
      width: 10px;
      height: 10px;
      background: #ffffff;
      z-index: 2;
      transform: rotate(45deg);
      bottom: -10px;
      right: 15px;
    }
    &-dropdown {
      z-index: 1;
      top: 48px;
      right: -40px;
      padding: 8px 0;
      position: absolute;
      width: 140px;
      filter: drop-shadow(0px 4px 24px rgba(15, 31, 74, 0.20));
      border-radius: 10px;    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
      div {
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 400;
        cursor: pointer;
        padding: 7px 13px;
        border-bottom: 1px solid #F3F5FA;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: #e7e7e7;
        }
      }
      &-red{
        color: #FF6161;
      }
    }
  }
  &-tooltip {
    display: none;
    position: absolute;
    width: 298px;
    height: 60px;
    background: #FFF;
    filter: drop-shadow(0px 4px 24px rgba(15, 31, 74, 0.20));
    border-radius: 10px;
    left: -285px;
    top: -8px;
    align-items: center;
    &-icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #0B4CFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 12px;
    }
    span {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.5px;
    }
  }
  &__need-book {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
    h2 {
      margin-top: 0;
    }
    span {
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: -0.5px;
      a {
        font-family: Inter, sans-serif;
        color: #2361FF;
        font-weight: 600;
      }
    }
  }
}
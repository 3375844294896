.tasks-project-kanban {
  h2 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 26px;
    font-weight: 700;
  }
  &__header {
    margin-bottom: 10px;
  }
  &__body {
    padding-bottom: 100px;
  }
  .mobile-crate-task-icon {
    position: fixed;
    width: 48px;
    height: 48px;
    background: #000000;
    box-shadow: 0px 8px 7px rgba(27, 55, 87, 0.25);
    bottom: 100px;
    right: 20px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: none;
  }

  &__tabs {
    display: flex;
    background: #FFFFFF;
    max-width: max-content;
    border-radius: 10px;
    &-item {
      color: #919AB3;
      padding: 15px 31px;
      border-radius: 10px;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.5px;
      cursor: pointer;
      background: #FFFFFF;
      &:first-child {
      }
      &:last-child {
      }
      &-active {
        border: 1px solid #000;
        color: #000;
      }
    }
  }
}

@media only screen and (max-width: 500px) {

}
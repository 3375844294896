.follow-up-tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #DADEEA;
  background: #F3F5FA;
  height: 48px;
  margin-top: 44px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 48px;
    cursor: pointer;
    span {
      font-family: Inter, sans-serif;
      font-size: 15px;
    }
    &.active {
      background: #fff;
    }
    &:nth-child(2) {
      border-left: 1px solid #DADEEA;
      border-right: 1px solid #DADEEA;
    }
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
  &__active-item {
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
    box-shadow: 0px 4px 24px 0px rgba(70, 94, 107, 0.10);
    position: relative;
    top: -1px;
  }
}
.container {
  max-width: 420px;
  margin: 0 auto;
}
.checkbox_label__3t9eS {

}
.label {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: -6px;
  color: #000000;
  /*a {
    color: #2361FF;
  }*/
}
.input {
  background: #FFFFFF;
  border: 1px solid #EAE9F2;
  box-sizing: border-box;
  border-radius: 5px;
  height: 52px;
  margin-top: 20px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  padding: 0 19px;
  &::placeholder {
    color: #bbb;
  }
}
.error {
  color: red;
  font-size: 12px;
  margin: 2px 0 0 8px;
}
.w50 {
  width: calc(50% - 6px);
}
.checkbox {
  margin-top: 22px;
  align-items: flex-start;
}
.checkbox > div {
  margin-right: 5px;
}
.client-kanban-header {
  display: flex;
  justify-content: space-between;
  &__heading {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;

    color: #000000;
  }
  &__managers {
    display: flex;
    align-items: center;
  }
  &__manager {
    display: flex;
    align-items: center;
    margin-left: 70px;
    &-data {
      margin-left: 10px;
    }
    &-photo {
      border-radius: 10px;
    }
    &-name {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.5px;
      color: #000000;
    }
    &-position {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #8A93AD;

    }
  }
}

@media only screen and (max-width: 500px) {
  .client-kanban-header {
    &__managers {
      display: none;
    }
  }
}
.payment-method-modal {
  h2 {
    font-family: Inter;
    color: #2361FF;
    font-size: 16px;
    letter-spacing: 5px;
    font-weight: 500;
  }
  h3 {
    font-family: Inter;
    font-weight: 700;
    font-size: 21px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  &__input {
    margin-bottom: 10px;
  }
  &__data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-input {
      width: 49%;
    }
  }
  &__button {
    width: 100%;
    margin-top: 20px;
  }
}

.multiselect{
  &__main {
    position: relative;
    .dropdown__container {
      min-width: 100%;
      z-index: 2;
    }
  }
  &__input {
    height: 42px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 13px 10px;
    background: #ffffff;
    font-family: Inter, sans-serif;
    color: #000000;
    font-size: 13px;
    cursor: pointer;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__label {
    color: #919AB3;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
    display: block;
  }
  &__option {
    margin-top: 10px;
    &:first-child {
      margin-top: 0px;
    }
    .custom_checkbox_not_active {
      background: #F3F5FA;
      box-shadow: 0px 0px 4px 0px rgba(155, 172, 233, 0.25) inset;
      width: 18px;
      height: 18px;
    }
    span {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      margin-left: 9px;
    }
  }
}
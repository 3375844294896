.legal-team {
  margin-top: 60px;
  &__heading {
    display: flex;
    align-items: center;
    h4 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    img {
      margin-left: 24px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  &__item {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 24px;
    &-data {
      margin-left: 10px;
    }
    &-img img {
      border-radius: 10px;
      cursor: pointer;
    }
    &-name {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      color: #000000;
    }
    &-position {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #8A93AD;
    }
  }
  &__tooltip {
    position: relative;
    img {
      cursor: pointer;
    }
    &-dropdown {
      display: none;
      position: absolute;
      padding: 15px;
      background: #ffffff;
      border-radius: 10px;
      width: 225px;
      right: 25px;
      top: -18px;
      font-size: 12px;
      line-height: 16px;
      box-shadow: 0px 4px 24px rgba(18, 34, 72, 0.19);
    }
    &:hover {
      .legal-team__tooltip-dropdown {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .legal-team {
    &__items {
      flex-direction: column;
    }
    &__item {
      margin-bottom: 14px;
    }
  }
}
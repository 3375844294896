.business_goals_kanban {
  &__header {
    h2 {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 26px;
      font-weight: 700;
    }
    &-select {
      max-width: 300px;
      margin-top: 15px;
    }
  }
  &__body {
    &-items {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      em {
        color: #7389C3;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 7.5px;
        line-height: 13px;
        display: block;
      }
    }
  }
  &__create-button {
    width: calc(25% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
    min-height: 180px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px dashed #77A193;
    background: linear-gradient(0deg, #EEF0F4 0%, #EEF0F4 100%), #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: #FFFFFF;
    }
    div {
      display: flex;
      align-items: center;
      span {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-left: 8px;
      }
    }
  }
  &__item {
    width: calc(25% - 10px);
    border-radius: 10px;
    margin: 0 10px 10px 0;
    background: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-heading {
      padding: 23px 20px;
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px solid #E4EAF8;
      border-radius: 10px 10px 0 0;
    }
    &-body {
      padding: 15px 20px;
      background: #FFF;
      flex-grow: 1;
    }
    &-button button {
      width: 100%;
      height: 44px;
      border-radius: 0 0 10px 10px;
      background: #000000;
      color: #FFF;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      border: none;
      &:hover {
        opacity: 0.7;
      }
    }
    &-progress-text {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      display: flex;
      &-circle {
        width: 7px;
        height: 7px;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    &-progress-line {
      height: 10px;
      margin-top: 6px;
      display: flex;
      div {
        height: 8px;
        border-radius: 100px;
        border: 1px solid #C9D3EE;
        margin-right: 1px;
      }
    }
    &-priority {
      margin-bottom: 10px;
      span, b {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
      }
    }
    &-important {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 40px;
    }
  }
  &__bs-with-image {
    height: 140px;
    div {
      flex-direction: column;
      max-width: 200px;
      p {
        color: #7389C3;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 7.5px;
        line-height: 13px;
      }
    }
  }
  &__bs-items {

  }
  &__bs-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.5px;
    padding: 8px 0;
    border-bottom: 1px solid #EEEFF6;
    &-circle {
      width: 8px;
      height: 8px;
      flex-shrink: 0;
      background: #2361FF;
      border-radius: 50%;
    }
    &:last-child {
      border: none;
    }
  }
}
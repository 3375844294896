.by-new-call-modal {
  h2 {
    color: #2361FF;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: 0;
    padding-left: 30px;
  }
  .modal__body {
    overflow: visible;
  }
  &__list {
    &-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      h4 {
        font-family: Inter, sans-serif;
        font-size: 21px;
        font-weight: 700;
      }
      &-count {
        span {
          font-family: Inter, sans-serif;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: -0.5px;
        }
        b {
          font-family: Inter, sans-serif;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: -1px;
          text-transform: uppercase;
        }
      }
    }
  }
  &__items {
    margin-top: 20px;
  }
  &__item {
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
    &-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h5 {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }
      em {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.5px;
      }
      &-left {
        display: flex;
        align-items: center;
      }
    }
    &-counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 15px;
      > img {
        cursor: pointer;
      }
      span {
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.5px;
        text-align: center;
        display: block;
        padding: 0 9px;
      }
    }
    &-content {
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.5px;
      margin-top: 18px;
      li {
        position: relative;
        line-height: 24px;
        margin-left: 17px;
      }
      li:before {
        content: "";
        background-image: url("../../../../assets/images/ok-green.svg");
        width: 11px;
        height: 11px;
        display: block;
        position: absolute;
        left: -17px;
        top: 6px;
      }
    }
  }
  &__total-price {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 18px;
  }
  &__none-credits {
    color: #FF5C5C;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 20px;
  }

  &__thanks {
    img {
      margin: 20px auto 0 auto;
      display: block;
    }
    h5 {
      font-family: Inter, sans-serif;
      font-size: 21px;
      font-weight: 700;
      text-align: center;
      margin-top: 24px;
    }
    p {
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.5px;
      margin-top: 16px;
      line-height: 16px;
      b {
        font-weight: 700;
      }
    }
  }
}
.client-layout {
  .header__right {
    background: none;
  }
  .header__right-link {
    border-radius: 50%;
  }
  .layout__sidebar {
    //margin-top: 0;
  }
  .layout__sidebar-nav {
    //background: none;
  }
  .layout__sidebar-nav-link {
    //padding: 13px 25px;
  }
  .layout__sidebar-profile {
    //background: #EEF0F4;
    //margin-top: 85px;
  }
}
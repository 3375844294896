.component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
}
.container {
  max-width: 610px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_image {

  }
  &_heading {
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
  }
  &_text {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
  }
}

.button {
  width: 262px;
  height: 58px;
  background: #000000;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 40px;
}
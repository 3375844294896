.payment-history {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px 40px;
  h2 {
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 40px;
  }
  &__table {
    width: 100%;
    thead {
      th {
        font-size: 11px;
        line-height: 13px;
        color: #A3A9B9;
        text-align: left;
      }
    }
    tbody {
      td {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.5px;
        border-bottom: 1px solid #F3F5FA;
        padding: 20px 0;
      }
    }
    &-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 42px;
    }
  }
}


@media only screen and (max-width: 500px) {
  .payment-history {
    padding: 20px 15px;
    h2 {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 21px;
    }
  }
  .payment-history-item {
    padding: 20px;
    border: 1px solid #E5EAF8;
    border-radius: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    &__element {
      width: 50%;
      flex-shrink: 0;
      margin-top: 15px;
      padding-right: 10px;
      span {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #A3A9B9;
      }
      p {
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: -0.5px;
        margin-top: 2px;
      }
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-top: 0;
      }
    }
    &__btn {
      width: 83px;
      height: 33px;
      border: 1px solid #000000;
      border-radius: 100px;
      letter-spacing: -0.5px;
      font-weight: 500;
      font-size: 13px;
      background: none;
    }
  }
}
@import "/src/assets/scss/variables";

.account-settings {
  display: flex;
  justify-content: center;
  &__input {
    margin-bottom: 15px;
  }
  &__container {
    width: 480px;
    background: #ffffff;
    border-radius: 10px;
    padding: 30px 40px;
    h2 {
      font-weight: bold;
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 20px;
    }
    h3 {
      margin-top: 25px;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
    button {
      width: 100%;
      margin-top: 15px;
    }
  }
  &__file {
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;
    &-input {
      width: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
    }
    &-container {
      width: 80px;
      height: 80px;
      background: linear-gradient(0deg, #EEF0F4, #EEF0F4), #FFFFFF;
      border: 1px dashed #77A193;
      box-sizing: border-box;
      border-radius: 10px;
      flex-shrink: 0;
      margin-right: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-block {
        background: #000000;
        border-radius: 5px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-text {
      display: flex;
      &-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 0;
        span {
          font-size: 11px;
          line-height: 15px;
          letter-spacing: -0.5px;
        }
        em {
          color: #A3A9B9;
          font-size: 11px;
          line-height: 15px;
        }
      }
      &-right {
        min-width: 120px;
        margin-left: 5px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        a, button {
          background: rgba(238, 240, 244, 0.36);;
          border-radius: 5px;
          border: none;
          font-size: 13px;
          color: #A3A9B9;
          width: 120px;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: $color_black;
            color: $color_light;
            text-decoration: none;
          }
        }
        button {
          margin-top: 0;
          background: red;
          cursor: pointer;
          color: #FFFFFF;
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .client_layout {
    .settings {
      padding: 15px;
    }
    .settings h1 {
      display: none;
    }
    .settings__nav {
      display: none;
    }
    .account-settings__container {
      width: 100%;
    }
    .account-settings__file-text-left {
      display: none;
    }
  }
}
.manager_profile {
  * {
    font-family: 'Inter', sans-serif;
  }
  .modal__container {
    min-height: 300px;
  }
  .modal__container__header {
    h2 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
    }
  }
  &__main {
    width: 100%;
  }
  &__heading {
    display: flex;
    margin-top: 40px;
    > img {
      margin-right: 14px;
      border-radius: 12px;
    }
  }
  &__body {
    height: 250px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cdd0d9;
      border-radius: 10px;
      // outline: 1px solid slategrey;
    }
  }
  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    > div {
      display: flex;
      align-items: center;
    }
    &-name {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.5px;
      margin-right: 10px;
      text-transform: capitalize;
    }
  }
  &__sum {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
  &__position {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #8A93AD;
    margin-top: 5px;
  }
  &__location {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    img {
      margin-right: 7px;
    }
  }
  &__info {
    margin-top: 30px;
    > div {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      img {
        margin-right: 5px;
      }
    }
    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      margin-top: 10px;
      em {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #8A93AD;
        display: block;
      }
    }
  }
}

.client-profile {
  &__body {
    margin-top: 30px;
  }
  &__row {
    display: flex;
  }
  &__col {
    width: 50%;
  }
  &__label {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    img {
      margin-right: 5px;
    }
  }
  &__email {
    margin-top: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 13px;
  }
  &__company-item {
    margin-top: 12px;
    &-name {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 13px;
    }
    &-link {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #8A93AD;
      margin-top: 3px;
    }
  }
}
.business_goal_view {
  // width: 610px;
  margin: 0 auto;
  &__breadcrumbs {
    span {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.5px;
    }
  }
  &__container {
    border-radius: 10px;
    background: #FFF;
    margin-top: 18px;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 25px 15px 25px;
  }
  &__switch {
    .checkout__checkbox {
      margin-right: 10px !important;
    }
    .checkout__checkbox-active {
      width: 22px;
      height: 22px;
    }
    .checkout__checkbox__container {
      height: 28px;
      width: 50px;
    }
    .checkout__checkbox span {
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.5px;
      text-transform: capitalize;
      margin: 0 7px;
    }
  }
  &__created-date {
    color: #8A93AD;
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    b {
      color: #000;
      font-family: Inter, sans-serif;
    }
  }
  &__heading {
    padding: 0 25px 25px 25px;
    border-bottom: 1px solid #EEEFF6;
    h2 {
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 21px;
      font-weight: 500;
      line-height: 24px;
    }
    &-priority {
      margin-top: 5px;
      span {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 400;
      }
      b {
        font-family: Inter, sans-serif;
      }
    }
  }
  &__description {
    padding: 15px 25px;
    border-bottom: 1px solid #EEEFF6;
  }
}

.project_suggestions {
  background: #fff;
  padding: 10px 0 0 0;
  border-radius: 10px;
  margin-top: 10px;
  &__client-head {
    &-heading {
      padding: 10px 25px 15px 25px;
      font-family: Inter, sans-serif;
      border-bottom: 1px solid #EEEFF6;
      font-weight: 500;
      font-size: 16px;
    }
    &-desc {
      padding: 0 25px;
      p {
        font-family: Inter, sans-serif;
        font-size: 13px;
        margin: 10px 0;
        line-height: 21px;
      }
      b {
        font-weight: 600;
      }
    }
  }
  &__create {
    width: calc(100% - 50px);
    height: 54px;
    border-radius: 10px;
    border: 1px dashed #77A193;
    background: linear-gradient(0deg, #EEF0F4 0%, #EEF0F4 100%), #FFF;
    display: flex;
    align-items: center;
    margin: 25px 25px 0 25px;
    cursor: pointer;
    div {
      margin-left: 15px;
      display: flex;
      align-items: center;
      span {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-left: 10px;
      }
    }
    &:hover {
      background: #ffffff;
    }
  }
  &__items {
    margin: 4px 25px 0 25px;
    padding-bottom: 50px;
  }
  &__item {
    margin-top: 12px;
    border-radius: 10px;
    border: 1px solid #E5EAF8;
    background: rgba(243, 245, 250, 0.40);
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid #E5EAF8;
      p {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.5px;
      }
      &-button {
        width: 117px;
        height: 26px;
        border-radius: 8px;
        border: 1px solid #E5EAF8;
        background: #FFF;
        color: #7389C3;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0 5px;
        + img {
          background: #FFFFFF;
        }
        &:hover {
          background: linear-gradient(0deg, #EEF0F4 0%, #EEF0F4 100%), #FFF;
        }
      }
    }
    &-bottom {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-status {
        span {
          color: #A3A9B9;
          font-family: Inter, sans-serif;
          font-size: 11px;
          font-weight: 400;
        }
        em {
          color: #000;
          font-family: Inter, sans-serif;
          font-size: 11px;
          font-weight: 500;
        }
      }
      &-button {
        font-family: Inter, sans-serif;
        font-size: 11px;
        width: 105px;
        height: 32px;
        border-radius: 100px;
        flex-shrink: 0;
        cursor: pointer;
      }
      &-button-white {
        color: #000;
        border: 1px solid #000;
        font-weight: 500;
        &:hover {
          background: #000000;
          color: #FFFFFF;
        }
      }
      &-button-black {
        background: #000000;
        color: #FFFFFF;
        &:hover {
          color: #000;
          border: 1px solid #000;
          background: #FFFFFF;
        }
      }
    }
  }
}

.project-suggestion-description-modal {
  &__heading {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-weight: 500;
    margin-top: 10px;
  }
  &__description {
    margin-top: 20px;
    flex-direction: column;
  }
}

.project-suggestion-description-modal {
  .project-suggestion-description-modal__description * {
    font-size: 14px;
  }
  ol, ul {
    margin: 5px 15px;
  }
}
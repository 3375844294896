.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hover-shadow-block {
  cursor: pointer;
  text-decoration: none;
  transition: .3s;
  &:hover {
    text-decoration: none;
    box-shadow: 0 4px 24px rgba($color_blue_2, 0.19);
  }
}

.inter { font-family: Inter, sans-serif }
.font-size-11 { font-size: 11px; }
.font-size-12 { font-size: 12px; }
.font-size-13 { font-size: 13px; }
.font-size-14 { font-size: 14px; }
.font-size-15 { font-size: 15px; }

.letter-spacing-1 { letter-spacing: 1px }

.text-uppercase { text-transform: uppercase; }
.text-center { text-align: center; }
.color-blue { color: $color_blue; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

textarea {
  max-width: 100%;
}

.flex { display: flex }
.alignCenter { align-items: center }
.alignStart { align-items: flex-start }
.justifySpaceBetween { justify-content: space-between }
.justifyCenter { justify-content: center }
.relative { position: relative; }

.p1 {
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  b {
    font-weight: 600;
  }
}

.heading3 {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
}

.heading4 {
  font-family: Inter, sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
}

.heading6 {
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 28px;
}

.mr5 { margin-right: 5px }
.mr10 { margin-right: 10px }
.mr15 { margin-right: 15px }
.mr20 { margin-right: 25px }
.mr25 { margin-right: 25px }

.mt5 { margin-top: 5px }
.mt10 { margin-top: 10px }
.mt15 { margin-top: 15px }
.mt20 { margin-top: 25px }
.mt25 { margin-top: 25px }
.mt30 { margin-top: 30px }
.mt40 { margin-top: 40px }
.mt50 { margin-top: 50px }
.mt60 { margin-top: 60px }

.w100 { width: 100%; }
.w80 { width: 80%; }
.w70 { width: 70%; }
.w60 { width: 60%; }
.w50 { width: 50%; }
.w40 { width: 40%; }
.w30 { width: 30%; }
.w20 { width: 20%; }

.text-weight-300 { font-weight: 300 }
.text-weight-400 { font-weight: 400 }
.text-weight-500 { font-weight: 500 }
.text-weight-600 { font-weight: 600 }
.text-weight-700 { font-weight: 700 }
.text-weight-800 { font-weight: 800 }
.text-weight-900 { font-weight: 900 }

.pointer {
  cursor: pointer;
}

.modal_overflow_hide {
  .modal__body { overflow: inherit !important; }
  .modal__container { overflow: inherit !important; }
}

.registration-quiz {
  background: #FFFFFF;
  border-top: 1px solid #EAE9F2;
  position: relative;
  &__mobile_description {
    display: none;
  }
  &__mobile-header {
    display: none;
  }
  &__header {
    padding: 29px 0;
    &-container {
      max-width: 1300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    h3 {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      max-width: 520px;
    }
    b {
      font-family: Inter, sans-serif;
      font-size: 32px;
      font-weight: 700;
    }
  }
  &__line {
    background: rgba(35, 97, 255, 0.1);
    width: 100%;
    height: 4px;
    position: relative;
    &-abs {
      position: absolute;
      width: 7px;
      height: 4px;
      background: #FFFFFF;
    }
    &-active {
      height: 4px;
      background: #2361FF;
    }
  }
  &__body {
    padding-top: 80px;
    background: #f3f5fa;
    /*background: url("/src/assets/images/registration/Ellipse1.png") right top no-repeat,
    url("/src/assets/images/registration/Ellipse2.png") left center no-repeat;*/
    background-size: 500px;
    padding-bottom: 90px;
  }
}

.quill-editor {
  max-width: 100%;
  word-break: break-word !important;
}

.task-body__description * {
  white-space: normal !important;
}
.book-new-follow-up {
  * {
    font-family: Inter, sans-serif;
  }
  h2 {
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    text-transform: uppercase;
    margin-top: 38px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 23px;
  }
  &__item {
    padding-bottom: 20px;
    width: 32.5%;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 15px;
    &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6) {
      margin-left: 1%;
    }
    &-icon {
      width: 56px;
      height: 56px;
      border-radius: 10px;
      background: #F3F5FA;
      margin: 25px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 17px;
      margin-top: 15px;
    }
    h5 {
      text-align: center;
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
      margin-top: 3px;
    }
    h6 {
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      margin-top: 15px;
    }
    b {
      display: block;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 17px;
    }
    p {
      max-width: 200px;
      text-align: center;
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
      margin: 10px auto 0 auto;
    }
    hr {
      border-top: 1px solid #DADEEA;
      border-bottom: 0;
      margin-top: 25px;
    }
    &-ul {
      margin: 16px auto 0 auto;
      width: fit-content;
      li {
        display: block;
        font-size: 11px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.5px;
        margin-bottom: 5px;
        position: relative;
        margin-left: 17px;
      }
      li:before {
        content: "";
        background-image: url("../../../assets/images/ok-green.svg");
        width: 11px;
        height: 11px;
        display: block;
        position: absolute;
        left: -17px;
        top: 6px;
      }
    }
    button {
      height: 42px;
      border-radius: 100px;
      background: #0B4CFF;
      width: 90%;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: none;
      color: #fff;
      cursor: pointer;
      margin: 17px auto 0 auto;
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
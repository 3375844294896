@import "./src/assets/scss/variables";

.comment-list {
  margin: 0 auto;
  background: $color_light;
  border-radius: 0 10px 10px 10px;
  padding: 15px 0 0 0;
  .quill {
    max-width: 100%;
  }
  .ql-editor {
    max-width: 90%;
  }
  &__desc {
    padding: 12px 0;
    background: #f5faff;
    width: 100%;
    color: #97a5c5;
    border-left: 3px solid #fce07a;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    line-height: 14px;
    position: relative;
    b {
      font-weight: bold;
    }
    &-close {
      font-weight: bold;
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;
      font-size: 13px;
    }
  }
  &__editor {
    width: 100%;
    .tox-tinymce {
      border-radius: 6px;
      width: 100%;
    }
    .tox-edit-area {
      margin-right: 80px;
    }
    .tox-sidebar-wrap {
      background: #FFFFFF;
      width: 100%;
    }
  }
  &__header {
    font-family: "Inter",sans-serif;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    //-webkit-justify-content: space-between;
    //justify-content: space-between;
    /*margin-bottom: 5px;
    padding: 0 30px 10px 30px;*/
    border-bottom: 1px solid #F3F5FA;
    &-el {
      display: flex;
      align-items: center;
      width: 217px;
      box-sizing: border-box;
      background: rgba(228,234,248, 0.5);
      padding: 7px 12px;
      border-radius: 10px 10px 0 0;
      cursor: pointer;
      height: 55px;
      &-active {
        background: #FFFFFF;
        position: relative;
        z-index: 1;
      }
      span {
        font-size: 16px;
        font-weight: 700;
        padding: 0 20px 0 10px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        em {
          height: 19px;
          width: 19px;
          border-radius: 50%;
          background: #FF5C5C;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
        }
      }
    }
  }
  &__group-photo {
    &-item {
      img {
        width: 42px;
        height: 42px;
        border-radius: 10px;
      }
    }
    &-items {
      display: flex;
      flex-wrap: wrap;
      width: 42px;
      img {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        margin: 1px;
      }
    }
    b {
      width: 16px;
      height: 16px;
      color: #8A93AD;
      letter-spacing: -1px;
      font-size: 11px;
      font-family: 'Inter', sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1px 2px;
    }
  }
  &__title {
    font-family: "Inter",sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  &__icon {
    margin-top: 5px;
    cursor: pointer;
  }
  &__body {
    background: #fff;
    height: 462px;
    max-height: 462px;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 30px;
    display: flex;
    flex-direction: column-reverse;
    // content-visibility: auto;
    // flex-direction: column;
  }
  &__input-futter {
    background: #eef0f4;
    display: block;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    position: relative;
  }
  &__textarea {
    padding-top: 12px;
    width: 100%;
    height: 72px;
    padding-left: 15px;
    border-radius: 10px 0 0 10px;
    border: none;
    padding-right: 43px;
  }
  &__input-image {
    background: #fff;
    padding-right: 15px;
    padding-bottom: 30px;
    border-radius: -10px 0 0 10px;
  }
  &__send-button {
    border-radius: 50%;
    background: #000000;
    width: 35px;
    height: 35px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 17px;
    top: 57px;
    &:disabled {
      opacity: 0.5;
    }
  }
  &__file {
    input {
      overflow: hidden;
      width: 0;
      height: 0;
      padding: 0;
    }
    img {
      position: absolute;
      width: 12px;
      right: 67px;
      top: 67px;
      cursor: pointer;
    }
  }
  &__search {
    height: 42px;
    width: 350px;
    border: 1px solid #B4BED9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    input {
      height: 100%;
      flex-grow: 1;
      border: none;
      outline: none;
      margin-right: 5px;
      border-radius: 4px;
    }
    img {
      margin: 8px;
      cursor: pointer;
    }
  }
}

.user-messages {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #F3F5FA;
  /*content-visibility: auto;*/

  &__message-found {
    background: #fffdbd;
    margin: 0 -15px;
    padding: 10px 15px;
  }
  &__avatar {
    display: -webkit-flex;
    display: flex;
  }
  &__img {
    margin-right: 10px;
    img {
      width: 36px !important;
      height: 36px !important;
      cursor: pointer;
    }
  }
  &__text {
    font-family: Inter;
    font-size: 13px;
    white-space: break-spaces;
    ul {
      padding-left: 20px;
      line-height: 24px;
      li {
        list-style-type: disc;
      }
    }
    ol {
      padding-left: 20px;
      line-height: 24px;
      li {
        list-style-type: decimal;
      }
    }
    strong {
      font-weight: 600;
    }
    em {
      font-style: italic;
    }
    b {
      font-weight: 700;
    }
  }
  &__chat-file {
    background: #f7f7f7;
    display: flex;
    align-items: center;
    padding: 1px 10px;
    border-radius: 7px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    //margin-bottom: 3px;
    margin: 0 5px 5px 5px;
    width: fit-content;
    &:hover {
      opacity: 0.8;
    }
    &:first-child {
      margin-top: 5px;
    }
    &-img {
      border-radius: 6px;
      width: 15px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      img {
        width: 11px;
      }
    }
    &-text {
      font-size: 14px;
      color: #646464;
    }
    &-button {
      img {
        width: 25px;
        margin-left: 18px;
        opacity: 0.6;
      }
    }
    &-close {
      color: #ff5c5c;
      margin-left: 25px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  &__text p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 21px;
    word-break: break-word;
  }
  &__sending-time {
    flex-shrink: 0;
    margin-left: 15px;
    display: flex;
  }
  &__sending-time__span {
    color: #8A93AD;
    font-size: 13px;
    line-height: 15px;
    font-family: Roboto;
    display: block;
    text-align: right;
  }
  &__edit {
    transition: all 0.1s ease-out;
    position: relative;
    padding-left: 10px;
    /*margin-right: -20px;*/
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.1s ease-out;
      width: 20px;
      height: 30px;
      overflow: auto;
    }
    &-dropdown {
      transition: all 0.1s ease-out;
      padding: 0;
      background: #ffffff;
      // box-shadow: 0px 4px 24px rgba(15, 31, 74, 0.2);
      border-radius: 10px;
      margin-top: 3px;
      position: absolute;
      top: -13px;
      right: 20px;
      overflow: hidden;
      width: 0;
      height: 0;
      border: 1px solid #e7e7e7;
      &-item {
        text-align: right;
        font-family: Inter, sans-serif;
        font-size: 13px;
        line-height: 20px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &:hover &-icon {
      transform: rotate(45deg);

    }
    &:hover &-dropdown {
      width: 85px;
      height: inherit;
      padding: 12px 10px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .client_layout {
    .comment-list {
      margin-top: 10px;
      .tox-tinymce {
        border: 0;
      }
      &__fullScreenIcon {
        display: none;
      }
    }
    .comment-list__body {
      height: 300px;
      padding: 0 12px;
    }
    .comment-list__input-futter {
      padding: 10px 0;
    }
  }
  .user-messages {
    flex-direction: column-reverse;
    &__sending-time {
      align-self: center;
      margin-left: 0;
      margin-bottom: 8px;
      span {
        text-align: left;
        em {
          margin-right: 10px;
        }
        br {
          display: none;
        }
      }
    }
    &__sending-time {
      display: none;
    }
    &__name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      b {
        font-weight: 600;
        font-size: 13px;
      }
      em {
        display: block !important;
        font-weight: 400;
        font-size: 13px;
        color: #8A93AD;
      }
    }
  }
}

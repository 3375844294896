.platform-date-range-picker {
  .rdrDefinedRangesWrapper, .rdrDateDisplay {
    display: none !important;
  }
  .rdrCalendarWrapper {
    border: 1px solid #B4BED9 !important;
    border-radius: 10px;
  }
  &__label {
    color: #919AB3;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
    display: block;
  }
  &__input {
    width: 230px;
    height: 42px;
    background: #ffffff;
    flex-shrink: 0;
    border-radius: 10px;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border: 1px solid #B4BED9 !important;
    cursor: pointer;
  }
  &__dropdown {
    position: absolute;
    z-index: 2;
  }
  &__close {
    color: #2361FF;
    margin-right: 10px;
    font-weight: bold;
    margin-top: 2px;
  }
}
.stripe-payment-methods {
  width: 480px;
  background: #FFFFFF;
  padding: 40px;
  border-radius: 10px;
  margin: 0 auto;
  box-sizing: content-box;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 21px;
    }
    button {
      height: 42px;
      padding: 0 47px;
      cursor: pointer;
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      letter-spacing: -0.5px;
    }
  }
  &__content {
    padding: 0 10px;
  }
  &__item {
    padding: 30px 0;
    border-bottom: 1px solid #000000;
    &:last-child {
      border: none !important;
    }
  }
  &__card {
    width: 237px;
    height: 147px;
    background: linear-gradient(0deg, #F3F5FA, #F3F5FA), #FFFFFF;
    /*border: 1px solid #2562FF;*/
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 20px;
    box-sizing: border-box;
    p {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 15px;
      letter-spacing: -0.5px;
    }
    a {
      font-family: 'Inter', sans-serif;
      letter-spacing: -0.5px;
      color: #FF5050;
      font-weight: 400;
      font-size: 11px;
    }
  }
  &__checkbox {
    margin-top: 15px;
    div > div {
      background: #F3F5FA;
      box-shadow: inset 0 0 4px rgba(155, 172, 233, 0.25);
      border-radius: 5px;
      height: 18px;
      width: 18px;
    }
    span {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: -0.5px;
      margin-left: 7px;
    }
  }
  &__checked {
    div {
      background: #2361FF !important;
    }
    img {
      width: 10px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .stripe-payment-methods {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    &__header {
      h4 {
        font-size: 15px;
      }
      button {
        height: 35px;
        padding: 0 35px;
      }
    }
  }
}
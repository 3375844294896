@import "./src/assets/scss/variables";
.create_project {
  .react-select, input {
    //margin-top: 15px;
  }
  .input, .react-select {
    margin-top: 15px;
  }
  &__heading {
    font-family: Inter;
    color: $color_blue;
    font-size: 16px;
    letter-spacing: 5px;
    font-weight: 500;
  }
  &__second-heading {
    font-family: Inter;
    font-weight: 700;
    font-size: 21px;
    padding: 34px 0 5px 0;
  }
  &__options {
    margin: 25px 0;
  }
  &__button {
    width: 100%;
  }
}
